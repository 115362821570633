export const GetCartDetails = /* GraphQL */ `
  query GetCartDetails {
    cart {
      details {
        storeCartId
        currencyCode
        coupons {
          id
          code
          discountedAmount
        }
        items {
          catalogProductId
          id
          parentId
          collectionName
          accessories {
            liner {
              catalogProductSlug
              id
              catalogProduct {
                name
              }
              images(input: { role: { equal: "front" } }) {
                role
                url
              }
            }
            plainEnvelope {
              catalogProductSlug
              id
            }
            printedEnvelope {
              catalogProductSlug
              id
              catalogProduct {
                name
              }
              images(input: { role: { equal: "front" } }) {
                role
                url
              }
            }
          }
          related {
            liner {
              slug
              variationId
            }
            plainEnvelopeId
            printedEnvelope {
              slug
              variationId
            }
          }
          portfolioItem {
            customizer {
              stateId
              urlPrint
              urlProofs {
                position
                url
              }
              userId
            }
            id
            memberId
            notes
            tenant
            urlPrintOverride
            variationId
            isCustomized
          }
          price {
            bulkPrices {
              amount
              quantity
            }
          }
          slug
          productName
          productPhotos {
            back
            front
            frontAndBack
            hero
            suite
          }
          quantity
          totalAmount
          variation {
            catalogProductId
            colorThemeCode
            optionValues {
              name
              valueId
              optionId
            }
            envelopePrintingOptionCode
            foilCode
            id
            isSample
            paperTypeCode
            productCode
            flapTypeCode
            suiteCode
            tenant
            optionValues {
              optionId
              name
              valueId
            }
            product {
              productFormat {
                name
              }
            }
            optionValues {
              name
              optionId
              valueId
            }
          }
          colorThemeName
          paperTypeName
          foilThemeName
          envelopePrintingName
          designedBy
          productTypeName
        }
        totalAmount
      }
      itemCount
    }
  }
`

export const ApplyCoupon = /* GraphQL */ `
  mutation ApplyCoupon($couponCode: String!) {
    cart {
      applyCoupon(couponCode: $couponCode) {
        storeCartId
        coupons {
          id
          code
          discountedAmount
        }
      }
    }
  }
`

export const RemoveCoupon = /* GraphQL */ `
  mutation RemoveCoupon($couponCode: String!) {
    cart {
      removeCoupon(couponCode: $couponCode) {
        storeCartId
      }
    }
  }
`

export const AddOrderedItemToCart = /* GraphQL */ `
  mutation AddOrderedItemToCart($input: AddItemToCartInput!) {
    cart {
      addItem(input: $input) {
        cart {
          storeCartId
        }
        item {
          totalAmount
        }
      }
    }
  }
`

export const AddOrderedSampleItemToCart = /* GraphQL */ `
  mutation AddOrderedSampleItemToCart($input: AddItemToCartInput!) {
    cart {
      addItem(input: $input) {
        item {
          portfolioItem {
            id
          }
        }
      }
    }
  }
`

const VariationData = /* GraphQL */ `
  fragment VariationData on CatalogProductVariationGraphqlModel {
    id
    isSample
    catalogProductTypeCode
    images {
      url
      role
    }
    priceTiers: friendlyPriceTiers {
      quantity
      totalAmount
    }

    optionValues {
      optionId
      valueId
      name
    }
    catalogProduct {
      name
      slug
      externalId
      catalogProductFormat {
        name
      }
      catalogProductCategory {
        name
      }
    }
  }
`

const Variation = /* GraphQL */ `
  fragment Variation on CartDetailsItem {
    variation: displayVariation {
      ...VariationData
    }
  }
  ${VariationData}
`

const Portfolio = /* GraphQL */ `
  fragment Portfolio on CartDetailsItem {
    portfolio: portfolioItem {
      id
      notes
      customizer {
        urlProofs {
          url
          position
        }
      }
    }
  }
`

const Accessories = /* GraphQL */ `
  fragment Accessories on CartDetailsItem {
    accessories {
      liner {
        id
        catalogProduct {
          name
          slug
        }
        images(input: { role: { equal: "front" } }) {
          role
          url
        }
      }
      plainEnvelope {
        ...VariationData
      }
      printedEnvelope {
        id
        catalogProduct {
          name
          slug
        }
        images(input: { role: { equal: "front" } }) {
          role
          url
        }
      }
    }
  }
`

const Details = /* GraphQL */ `
  fragment Details on CartDetails {
    storeCartId
    totalAmount
    cartAmount
    items {
      id
      quantity
      totalAmount
      parentId
      productTypeName
      ...Portfolio
      ...Variation
      ...Accessories
    }
  }
  ${Variation}
  ${Portfolio}
  ${Accessories}
`

export const GetCart = /* GraphQL */ `
  query getCart {
    cart {
      details {
        ...Details
      }
    }
  }
  ${Details}
`

export const RemoveCartItems = /* GraphQL */ `
  mutation removeCartItems($input: RemoveFromCartInput!) {
    cart {
      removeItems(input: $input) {
        cart {
          storeCartId
        }
      }
    }
  }
`

export const UpdateCartItems = /* GraphQL */ `
  mutation updateCartItems($input: UpdateCartItemInput!) {
    cart {
      updateItems(input: $input) {
        cart {
          storeCartId
        }
      }
    }
  }
`

export const MoveCartItemToDrafts = /* GraphQL */ `
  mutation moveCartItemToDrafts($itemId: String!) {
    draftCart {
      moveCartItemToDrafts(itemId: $itemId) {
        cart {
          storeCartId
        }
        draft {
          id
        }
      }
    }
  }
`
